import React from 'react';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { hostNameBack } from '../../../utils/constants';
import Loader from "../../../utils/Loader"

function CatsDropdown({ isProduct, closeDropdowns }) {

  const [categories, setCategories] = React.useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {

    axios.get(`${hostNameBack}/api/v1/categories/${isProduct ? "product" : "service"}/all`)
      .then(response => {
        if (response.data.success)
          setCategories(response.data.categories);
        else
          enqueueSnackbar("Something went wrong!", { variant: "error" });
      })
      .catch(e => {
        enqueueSnackbar(e.response?.data?.error || "Something went wrong!", { variant: "error" });
      }).finally(() => {
        setIsLoading(false);
      })

    // const timer = setTimeout(dump, 3000);
    // return () => clearTimeout(timer);

  }, [isProduct]);

  const handleCategoryClick = (categoryName) => {
    navigate(`/${isProduct ? "products" : "services"}?category=${categoryName}`);
    closeDropdowns();
  };
  
  const categoriesElems = categories.length > 0 ? categories.map((category, index) => {
    return <div key={index} className='cat' onClick={() => handleCategoryClick(category.name)}>
      {category.name}
      <MdKeyboardArrowRight />
    </div>;
  }) : <div style={{ color: "black" }}>Nothing to show here</div>;

  return (
    <div className='catsDropdownDiv'>
      {isLoading ? <Loader /> : <div className="catsDropdownContent">
        {categoriesElems}
      </div>}
    </div>
  );
}

export default CatsDropdown;
