import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import { hostNameBack, tommorrow } from '../../utils/constants';
import { IoIosCloseCircleOutline } from 'react-icons/io';

function TradeleadStepper() {
    const [currentStep, setCurrentStep] = useState(1);
    const [categories, setCategories] = useState([]);
    const [showTradeleadModel, setShowTradeleadModel] = useState(false);
    const [quoteType, setQuoteType] = useState('product');
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        category: '',
        budget: 5,
        duration: 1,
        quantity: 1,
        expiryDate: ''
    });

    

    useEffect(() => {
        axios.get(`${hostNameBack}/api/v1/categories/${quoteType}/all`)
            .then(response => {
                if (response.data.success) {
                    const fetchedCategories = response.data.categories;
                    setCategories(fetchedCategories);
                    setFormData(prev => ({
                        ...prev,
                        category: fetchedCategories[0]?.name
                    }))
                }
            })
            .catch(e => {
                enqueueSnackbar(e.response?.data?.error || 'Something went wrong!', { variant: 'error' });
            });
    }, [quoteType]);

    const handleInputChange = (e) => {

        const { name, value } = e.target;

        if (name === "budget" && (value < 0 || value > 1000000))
            return;
        else if (name === "duration" && (value < 0 || value > 1000))
            return;

        setFormData({ ...formData, [name]: value });
    };

    const handleNext = () => {
        if (currentStep < 4) setCurrentStep(currentStep + 1);
    };

    const handleBack = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
    };

    const handleSubmit = async () => {
        const token = localStorage.getItem('token');
        
        for (let item in formData) {
            if (formData[item] === '' || (item === "budget" && formData[item] === 0)) {
                enqueueSnackbar("All Fields are Required!", { variant: 'error' });
                return;
            }
        }

        if (formData.budget < 5) {
            enqueueSnackbar("Budget can't be less than $5", { variant: "warning" })
            return
        }
        else if (formData.quantity < 1 && quoteType === "product") {
            enqueueSnackbar("Quantity can't be less than 1", { variant: "warning" })
            return
        }
        else if (formData.duration < 1 && quoteType === "service") {
            enqueueSnackbar("Duration can't be less than 1", { variant: "warning" })
            return
        }


        try {
            const response = await axios['post'](`${hostNameBack}/api/v1/tradeleads/request`, { ...formData, requestFor: quoteType }, { headers: { Authorization: `Bearer ${token}` } });
            if (response.data.success) {
                enqueueSnackbar(`Request posted successfully`, { variant: 'success' });
                setShowTradeleadModel(false);
                setFormData({
                    title: '',
                    description: '',
                    category: '',
                    budget: 5,
                    duration: 1,
                    quantity: 1,
                    expiryDate: ''
                });
                setCurrentStep(1);
            } else {
                enqueueSnackbar('Something went wrong!', { variant: 'error' });
            }
        } catch (e) {
            console.log(e);
            enqueueSnackbar(e.response?.data?.error || 'Something went wrong!', { variant: 'error' });
        }
    };

    return (
        <div className="tradeleadStepper">

            <div className="form heroPageForm">
                <div className="inputDiv">
                    <label>I'm looking for</label>
                    <select style={{marginTop: "10px"}} name="quoteType" className='inputField dropdownPlus' value={quoteType} onChange={(e) => setQuoteType(e.target.value)}>
                            <option value="product">Product</option>
                            <option value="service">Service</option>
                        </select>
                </div>
                <div className="mainStepBtn">
                    <button className="rightArrow" onClick={()=>setShowTradeleadModel(true)}><MdKeyboardArrowRight className='arrowIcon' /></button>
                </div>
            </div>

            {showTradeleadModel && (
                <div className="showTradeleadModelDiv popupDiv">
                    <div className="popupContent">
                        <div>

                            <div className="stepper">
                                <div className={`step ${currentStep === 1 ? 'active' : ''} ${currentStep > 1 ? 'completed' : ''}`}>1</div>
                                <div className={`stepConnector ${currentStep > 1 ? 'active' : ''}`}></div>
                                <div className={`step ${currentStep === 2 ? 'active' : ''} ${currentStep > 2 ? 'completed' : ''}`}>2</div>
                                <div className={`stepConnector ${currentStep > 2 ? 'active' : ''}`}></div>
                                <div className={`step ${currentStep === 3 ? 'active' : ''} ${currentStep > 3 ? 'completed' : ''}`}>3</div>
                                <div className={`stepConnector ${currentStep === 4 ? 'active' : ''}`}></div>
                                <div className={`step ${currentStep === 4 ? 'active' : ''}`}>4</div>
                            </div>

                            <div className="stepContent">

                                {currentStep === 1 && (
                                    <div className="step1 stepInputs">
                                        <div className="tradeleadInputDiv">
                                            <label>Give a brief Title</label>
                                            <input
                                                type="text"
                                                name="title"
                                                value={formData.title}
                                                onChange={handleInputChange}
                                                placeholder="Enter title"
                                                className='inputField'
                                            />
                                        </div>
                                    </div>
                                )}

                                {currentStep === 2 && (
                                    <div className="step2 stepInputs">
                                        <div className="tradeleadInputDiv">
                                            <label>Category</label>
                                            <select
                                                name="category"
                                                value={formData.category}
                                                onChange={handleInputChange}
                                                className='inputField'
                                                required
                                            >
                                                {categories.map((category, index) => (
                                                    <option key={index} value={category.name}>{category.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="tradeleadInputDiv">
                                            <label>Budget ($)</label>
                                            <input
                                                type="number"
                                                name="budget"
                                                value={formData.budget}
                                                onChange={handleInputChange}
                                                placeholder="Enter budget"
                                                className='inputField'
                                            />
                                        </div>
                                    </div>
                                )}

                                {currentStep === 3 && (
                                    <div className="step3 stepInputs">
                                        <div className="tradeleadInputDiv">
                                            {quoteType === "service" ? <><label>Duration</label>
                                                <input
                                                    type="number"
                                                    name="duration"
                                                    value={formData.duration}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter duration"
                                                    className='inputField'
                                                /></> : <><label>Quantity</label>
                                                <input
                                                    type="number"
                                                    name="quantity"
                                                    value={formData.quantity}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Quantity"
                                                    className='inputField'
                                                /></>}
                                        </div>
                                        <div className="tradeleadInputDiv">
                                            <label>Offer Expires On</label>
                                            <input
                                                type="date"
                                                name="expiryDate"
                                                value={formData.expiryDate}
                                                onChange={handleInputChange}
                                                className='inputField tradeleadDate'
                                                min={tommorrow}
                                            />
                                        </div>
                                    </div>
                                )}

                                {currentStep === 4 && (
                                    <div className="step4 stepInputs">
                                        <div className="tradeleadInputDiv">
                                            <label>Description</label>
                                            <textarea
                                                name="description"
                                                value={formData.description}
                                                onChange={handleInputChange}
                                                placeholder="Enter brief description"
                                                className='inputField tradeleadDescription'
                                            />
                                        </div>
                                    </div>
                                )}

                            </div>

                            <div className="stepButtons">
                                <button className="leftArrow" onClick={handleBack}>
                                    <MdKeyboardArrowLeft className='arrowIcon' />
                                </button>
                                <button className="rightArrow" onClick={currentStep < 4 ? handleNext : handleSubmit} >
                                    {currentStep < 4 && <MdKeyboardArrowRight className='arrowIcon' />}
                                    {currentStep === 4 && <FaCheck className='arrowIcon' />}
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className="popupCloseBtn">
                        <IoIosCloseCircleOutline className="icon" onClick={() => {setShowTradeleadModel(false); setCurrentStep(1)}} />
                    </div>
                </div>
            )}

        </div>
    );
}

export default TradeleadStepper;