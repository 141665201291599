import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from "../../utils/AuthContext";
import { enqueueSnackbar } from 'notistack';
import {Link} from "react-router-dom"

import SellerBasicDetails from "../../components/seller/SellerBasicDetails"
import Orders from '../common/Orders';
import { hostNameBack } from '../../utils/constants';
import Loader from '../../utils/Loader';

function SellerDashboard() {
  const { isLogin, user } = useContext(AuthContext);
  const [sellerDetails, setSellerDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSellerDetails = async () => {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${hostNameBack}/api/v1/sellers/seller/${user.sellerId._id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }); 
        setSellerDetails(response.data.seller);
      } catch (error) {
        console.log(error);
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }finally{
        setIsLoading(false);
      }
    };

    if (isLogin && user.role === "seller") {
        fetchSellerDetails();
      // const timer = setTimeout(fetchSellerDetails, 5000)
      // return ()=> clearTimeout(timer);
    }
  }, [isLogin, user]);

  return (
    <div className="sellerDashboard" style={{margin: "50px 0px"}}>
      <div className="sellerDashboardContent">

        {isLogin? user.role==="seller"? 
        <>
          {isLoading? <Loader type="simpleMini" /> : <SellerBasicDetails sellerDetails={sellerDetails} />}
          <Orders pageType="dashboard" />
        </>
        : <>
                <div>You are not a seller. Please create seller account to access this page</div>
                <Link to="/seller/becomeaseller">Become a Seller</Link>
              </>
        : <div>Please login to access this page</div>}
    
      </div>
    </div>
  );
}

export default SellerDashboard;
